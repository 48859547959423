import React from "react";
import { MEDIA_QUERIES, theme } from "@ifgengineering/component-library";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Line,
} from "recharts";
import { useMediaQuery } from "react-responsive";

const data = [
  { name: "Funders in Good", value: 33 },
  { name: "Islamic Relief", value: 11 },
  { name: "Muslim Hands", value: 11 },
  { name: "Muslim Aid", value: 11 },
  { name: "NZF", value: 34 },
];

const COLORS = ["#2FFFFF", "#D2FBD4", "#FDF6B5", "#57FDB7", "#549B9E"];

const CharitiesPieChart: React.FC = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });

  const legendFormatter = (value: string) => (
    <span style={{ color: theme.colors.SLATE600, fontSize: "14px" }}>
      {value}
    </span>
  );

  const RADIAN = Math.PI / 180;
  const mobileCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,

    percent,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={theme.colors.SLATE600}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const desktopCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={theme.colors.SLATE600}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {data[index].name}: {value}%
        <Line type="step" />
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={isMobile ? 400 : 450}>
      <PieChart width={600}>
        <Pie
          dataKey="value"
          nameKey="name"
          data={data}
          isAnimationActive={false}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={120}
          labelLine={isMobile ? false : true}
          allowReorder="yes"
          label={isMobile ? mobileCustomizedLabel : desktopCustomizedLabel}
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {isMobile && <Legend formatter={legendFormatter} />}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CharitiesPieChart;
